import gql from 'graphql-tag';
import { globalfunc } from '../../../../shared/GlobalFunction.js';

class PayrollService {
    readQuery(){
        var query = `query($month:Int!, $year:Int!, $employee_id:Int){
            GetPayroll(month:$month, year:$year, employee_Id: $employee_id){
                payroll_id,
                employee_id,
                month,
                year,
                employee_name,
                department,
                total_salary,
                total_daily_salary,
                total_debt
            }
        }`;
        return query;
    }

    async readQueryPayrollById(variables){
        var query = gql`query($month:Int!, $year:Int!, $employee_id:Int, $payroll_id:Int){
            GetPayroll(month:$month, year:$year, employee_Id:$employee_id, payroll_id:$payroll_id){
                    payroll_id,
                    employee_id,
                    month,
                    employee_name,
                    year,
                    total_attendance
                    meal_allowance,
                    daily_allowance,
                    transport_allowance,
                    total_daily_salary,
                    fixed_positional_allowance,
                    daily_positional_allowance,
                    motorcycle_rental_allowance,
                    trip_allowance,
                    kost_allowance,
                    pulse_allowance,
                    insurance_subtraction,
                    late_subtraction,
                    absence_subtraction,
                    base_salary,
                    craft_subtraction,
                    debt_subtraction,
                    total_debt,
                    total_late,
                    total_absence,
                    annual_paid_leave,
                    remaining_paid_leave,
                    montly_paid_leave,
                    other_allowance,
                    daily_salary
            }
        }`;
        
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetPayroll[0];   
    }

    payrollDetailTemplate(data){
        var html = `
            <div class="row p-1" width="100%">
                <div class="col-4 px-2">
                    <label class="form-label"><b>Payroll Detail</b></label>
                </div>
            </div>
            <div class="row p-1" width="100%">
                <div class="col-4 px-2">
                    <div class="p-3" width="100%" style="border-style:solid; border-radius: 3px; border-width: thin; border-color:lightgrey">
                        <div class="row" width="100%">
                            <div class="col-7">
                                <label class="form-label">Total Attendance</label>
                                <label class="form-label" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-2">
                                <label class="form-label">`+ data.total_attendance +`</label>
                            </div>
                        </div>
                        <div class="row" width="100%">
                            <div class="col-7">
                                <label class="form-label">Tunjangan Makan</label>
                                <label class="form-label" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-2">
                                <label class="form-label">`+ data.meal_allowance +`</label>
                            </div>
                        </div>
                        <div class="row" width="100%">
                            <div class="col-7">
                                <label class="form-label">Tunjangan Harian</label>
                                <label class="form-label" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-2">
                                <label class="form-label">`+ data.daily_allowance +`</label>
                            </div>
                        </div>
                        <div class="row" width="100%">
                            <div class="col-7">
                                <label class="form-label">Tunjangan Transportasi</label>
                                <label class="form-label" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-2">
                                <label class="form-label">`+ data.transport_allowance +`</label>
                            </div>
                        </div>
                        <div class="row" width="100%">
                            <div class="col-7">
                                <label class="form-label">Gaji Harian</label>
                                <label class="form-label" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-2">
                                <label class="form-label">`+ data.daily_salary +`</label>
                            </div>
                        </div>
                        <div class="row" width="100%">
                            <div class="col-7">
                                <label class="form-label">Total Gaji Harian</label>
                                <label class="form-label" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-2">
                                <label class="form-label">`+ data.total_daily_salary +`</label>
                            </div>
                        </div>
                        <div class="row" width="100%">
                            <div class="col-7">
                                <label class="form-label">Tunjangan Karyawan Tetap</label>
                                <label class="form-label" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-2">
                                <label class="form-label">`+ data.fixed_positional_allowance +`</label>
                            </div>
                        </div>
                        <div class="row" width="100%">
                            <div class="col-7">
                                <label class="form-label">Tunjangan harian Positional</label>
                                <label class="form-label" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-2">
                                <label class="form-label">`+ data.daily_positional_allowance +`</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4 px-2">
                    <div class="p-3" width="100%" style="border-style:solid; border-radius: 3px; border-width: thin; border-color:lightgrey">
                        <div class="row" width="100%">
                            <div class="col-7">
                                <label class="form-label">Tunjangan Motor rental</label>
                                <label class="f
                                orm-label" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-2">
                                <label class="form-label">`+ data.motorcycle_rental_allowance +`</label>
                            </div>
                        </div>
                        <div class="row" width="100%">
                            <div class="col-7">
                                <label class="form-label">Tujangan Perjalanan</label>
                                <label class="form-label" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-2">
                                <label class="form-label">`+ data.trip_allowance +`</label>
                            </div>
                        </div>
                        <div class="row" width="100%">
                            <div class="col-7">
                                <label class="form-label">Tunjangan Kost</label>
                                <label class="form-label" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-2">
                                <label class="form-label">`+ data.kost_allowance +`</label>
                            </div>
                        </div>
                        <div class="row" width="100%">
                            <div class="col-7">
                                <label class="form-label">Tunjangan Pulsa</label>
                                <label class="form-label" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-3"
                                <label class="form-label">`+ data.pulse_allowance +`</label>
                            </div>
                        </div>
                        <div class="row" width="100%">
                            <div class="col-7">
                                <label class="form-label">Potongan Asuransi</label>
                                <label class="form-label" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-2">
                                <label class="form-label">`+ data.insurance_subtraction +`</label>
                            </div>
                        </div>
                        <div class="row" width="100%">
                            <div class="col-7">
                                <label class="form-label">Potongan Keterlambatan</label>
                                <label class="form-label" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-2">
                                <label class="form-label">`+ data.late_subtraction +`</label>
                            </div>
                        </div>
                        <div class="row" width="100%">
                            <div class="col-7">
                                <label class="form-label">Potongan Absen</label>
                                <label class="form-label" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-2">
                                <label class="form-label">`+ data.absence_subtraction +`</label>
                            </div>
                        </div>
                        <div class="row" width="100%">
                            <div class="col-7">
                                <label class="form-label">Gaji Pokok</label>
                                <label class="form-label" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-3">
                                <label class="form-label">`+ data.base_salary +`</label>
                            </div>
                        </div>
                        <div class="row" width="100%">
                            <div class="col-7">
                                <label class="form-label">Craft Subtraction</label>
                                <label class="form-label" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-3">
                                <label class="form-label">`+ data.craft_subtraction +`</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4 px-2">
                    <div class="p-3" width="100%" style="border-style:solid; border-radius: 3px; border-width: thin; border-color:lightgrey">
                        <div class="row" width="100%">
                            <div class="col-7">
                                <label class="form-label">Debt Subtraction</label>
                                <label class="form-label" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-3"
                                <label class="form-label">`+ data.debt_subtraction +`</label>
                            </div>
                        </div>
                        <div class="row" width="100%">
                            <div class="col-7">
                                <label class="form-label">Total Utang</label>
                                <label class="form-label" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-3">
                                <label class="form-label">`+data.total_debt +`</label>
                            </div>
                        </div>
                        <div class="row" width="100%">
                            <div class="col-7">
                                <label class="form-label">Tunjangan Lain</label>
                                <label class="form-label" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-3">
                                <label class="form-label">`+ data.other_allowance +`</label>
                            </div>
                        </div>
                        <div class="row" width="100%">
                            <div class="col-7">
                                <label class="form-label">Total Terlambat</label>
                                <label class="form-label" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-3">
                                <label class="form-label">`+ data.total_late +`</label>
                            </div>
                        </div>
                        <div class="row" width="100%">
                            <div class="col-7">
                                <label class="form-label">Total Absen</label>
                                <label class="form-label" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-3">
                                <label class="form-label">`+ data.total_absence +`</label>
                            </div>
                        </div>
                        <div class="row" width="100%">
                            <div class="col-7">
                                <label class="form-label">Cuti Tunjangan Tahunan</label>
                                <label class="form-label" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-3"
                                <label class="form-label">`+ data.annual_paid_leave +`</label>
                            </div>
                        </div>
                        <div class="row" width="100%">
                            <div class="col-7">
                                <label class="form-label">Sisa Cuti</label>
                                <label class="form-label" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-3">
                                <label class="form-label">`+data.remaining_paid_leave +`</label>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        <hr>`;
        return html;
    }

    async deleteQuery(variables){
        const query = gql`mutation($id:Int!){
            DeletePayroll(PayrollId:$id)
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)   ; 
    }

    async addQuery(variables){
        const query = gql`
            mutation ($data:NewPayroll!) {
                CreatePayroll (NewPayroll:$data)
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables);
    }
    
    async editQuery(variables){
        const query = gql`
            mutation ($id:Int!, $data:NewPayroll!){
                UpdatePayroll (PayrollId:$id, NewPayroll:$data)
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables);
    }

    async getEmployeeIdQuery(){
        var query = gql`query{
            GetEmployee{
              Employee{
                employee_id,
                fullname
              }
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
       
        var arrayData = [];
        if(result.data.GetEmployee.Employee != null){
            for (let i = 0; i < result.data.GetEmployee.Employee.length; i++) {
                var label = result.data.GetEmployee.Employee[i].fullname
                var str = {value:result.data.GetEmployee.Employee[i].employee_id, label:label}
                arrayData.push(str);
            }
        }
        return arrayData;
    }  

    async getPayrollEstimation(variables) {
        var query = gql`query($month:Int!,$year:Int!,$employee_id:Int!){
            GetPayrollEstimation(month:$month,employee_Id:$employee_id,year:$year){
                payroll_id
                employee_id,
                month,
                year,
                total_attendance,
                meal_allowance,
                daily_allowance,
                transport_allowance,
                daily_salary,
                total_daily_salary,
                fixed_positional_allowance,
                daily_positional_allowance,
                motorcycle_rental_allowance,
                trip_allowance,
                kost_allowance,
                pulse_allowance,
                insurance_subtraction,
                late_subtraction,
                absence_subtraction,
                base_salary,
                craft_subtraction,
                debt_subtraction,
                total_debt,
                total_late,
                other_allowance,
                total_absence,
                annual_paid_leave,
                remaining_paid_leave,
                montly_paid_leave,
                employee_name,
                department,
                total_salary
                created_at,
                last_update
            }
        }`; 
        var result = await globalfunc.defaultApolloMutationDefault(query, variables);
        return result.data.GetPayrollEstimation;
    }
}

export default new PayrollService();