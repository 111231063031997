import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class WarningLetterService {
    readQuery(){
        var query = `query{
            GetWarningLetter{ 
                employee_Id
                employee_name
                warning_letter_id
                reason
                expired_date
                received_date
                level
                status
            }
        }`;
        return query;
    }
    
    async getWarningLetterData(employeeId) {
        const variables = {
            id : employeeId
        }
        var query = gql`
            query ($id:Int) {
                GetWarningLetter (employee_id:$id) {
                    employee_Id
                    employee_name
                    warning_letter_id
                    reason
                    expired_date
                    received_date
                    level
                    status
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        
        return result.data.GetWarningLetter[0];
    }

    async getEmployeeIdQuery(){
        var query = gql`query{
            GetEmployee{
              Employee{
                employee_id,
                fullname
              }
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
       
        var arrayData = [];
        if(result.data.GetEmployee.Employee != null){
            for (let i = 0; i < result.data.GetEmployee.Employee.length; i++) {
                var label = result.data.GetEmployee.Employee[i].fullname
                var str = {value:result.data.GetEmployee.Employee[i].employee_id, label:label}
                arrayData.push(str);
            }
        }
        return arrayData;
    }  

    async deleteQuery(variables){
        const query = gql`mutation($id:Int!){
            DeleteWarningLetter(warning_letter_id:$id)
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async addQuery(variables){
        const query = gql`
            mutation ($data:NewWarningLetter!) {
                CreateWarningLetter (NewWarningLetter:$data)
            }
        `
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    
    async editQuery(variables){
        const query = gql`
            mutation ($id:Int!, $data:NewWarningLetter!) {
                UpdateWarningLetter (warning_letter_id:$id, NewWarningLetter:$data)
            }
        `
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
}


export default new WarningLetterService();