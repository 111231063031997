<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Payroll</h2>
            </CCardHeader>
            <CCardBody>
                <CRow>
                    <CCol sm="2">
                        <label class="form-label font-weight-bold">Period</label>
                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                    </CCol>
                    <CCol sm="4" >
                        <date-picker
                            v-model="Period"
                            @input="periodSelected()"
                            format="MMM YYYY"
                            type="month"
                            placeholder="Pilih Period"
                            value-type="M-YYYY"
                            style="width:75%"
                            v-bind:clearable="false"
                        ></date-picker>
                    </CCol>
                    <CCol sm="2">
                        <label class="form-label font-weight-bold">Karyawan</label>
                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                    </CCol>
                     <CCol sm="4">
                        <v-select id="EmployeeName" class="pb-3" :options="EmployeeIdData" v-model="Employee" @input="onChangeEmployeeId()" />
                    </CCol>
                </CRow>
                
                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"                   
                    @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div>

                <payroll-grid :key="gridReload" :deleteClick="deleteClick" :editClick="editClick" :period="Period" :employeeId = "EmployeeId" />
                <payroll-form ref="payrollForm" :reload="reload" />
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import '@progress/kendo-ui';
import '@progress/kendo-theme-default/dist/all.css';
import payrollService from '../Payroll/Script/PayrollService.js';
import payrollGrid from '../Payroll/Grid/PayrollGrid.vue';
import payrollForm from '../Payroll/Component/PayrollForm.vue';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { response } from '../../../infrastructure/constant/response';
import { sweet_alert } from '../../../infrastructure/constant/variable';
import moment from 'moment';

export default {
    name: 'Department',
    components: {
        'payroll-grid': payrollGrid,
        'payroll-form': payrollForm,
        'date-picker': datePicker
    },
    async mounted () {
        var permission = this.$globalfunc.permissionModule('Payroll');
        document.getElementById("add").disabled = permission.cperms ? false : true;

        this.EmployeeIdData  = await payrollService.getEmployeeIdQuery();
    },
    data(){
        var period = this.periodSelected();
        
        return{
            gridReload: 0,
            Period: period,
            EmployeeName: '',
            EmployeeId:'',
            Employee:'',
            EmployeeIdData : [],
        };
    },
    methods: {
        addClick(){
            this.$refs.payrollForm.addClick();
        },
        editClick(data, view){
            this.$refs.payrollForm.editClick(data, view);
        },
        deleteClick(id){
            this.$swal(sweet_alert.delete).then((result) => {
                var variables = {
                    id : id
                }
                if (result.isConfirmed == true) {
                    payrollService.deleteQuery(variables).then(res =>{
                        this.reload();
                        this.$swal("Info", response.successDelete, "success");
                    })
                    .catch(e => {
                        var errorMessage = this.$globalfunc.ErrorExtractor(e)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
        reload(){
            this.gridReload++;
        },
        periodSelected(){
            this.reload();
            return this.Period == null ? moment(new Date()).format("MM-YYYY") : this.Period;
        },
        onChangeEmployeeId(){
            this.EmployeeId = this.Employee == null ? null : this.Employee.value;
            this.reload();
        }
    }
}
</script>

<style scoped>
</style>